import { createContext, useEffect, useState, useContext, useMemo } from 'react';
import { VIEWPORT_WIDTHS } from 'utils/constants';
import throttle from 'lodash/throttle';

const viewportContext = createContext({}),
    isSSR = typeof window === 'undefined',
    ViewportProvider = ({ clientDevice, children }) => {
        const [width, setWidth] = useState(
                isSSR ? VIEWPORT_WIDTHS[clientDevice] || 0 : window.innerWidth,
            ),
            [height, setHeight] = useState(isSSR ? 0 : window.innerHeight),
            [pixelDensity, setpixelDensity] = useState(
                isSSR ? 1 : window.devicePixelRatio,
            );

        useEffect(() => {
            const handleWindowResize = throttle(() => {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
                setpixelDensity(window.devicePixelRatio);
            }, 500);

            window.addEventListener('resize', handleWindowResize);

            return () =>
                window.removeEventListener('resize', handleWindowResize);
        }, []);

        return (
            <viewportContext.Provider
                value={useMemo(() => ({ width, height, pixelDensity }), [])}
            >
                {children}
            </viewportContext.Provider>
        );
    },
    useViewport = () => {
        const { width, height, pixelDensity } = useContext(viewportContext);
        return { width, height, pixelDensity };
    };

export { ViewportProvider, useViewport };
